import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserCredential } from "firebase/auth";

const initialState = {
  value: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.value = action.payload; // Guardar los datos del usuario
    },
    logout: (state) => {
      state.value = null; // Eliminar los datos del usuario
    },
  },
})

export const { login, logout } = authSlice.actions
export default authSlice.reducer