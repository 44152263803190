interface User {
    uid: string
    name: string
    email: string
    jobTitle: string
}

const users: User[] = [
    {
        uid: '53XVuXbxMNRqWjU0uLrU82qSTKH2',
        email: 'aline.tapia@cmpc.com',
        name: 'Aline Tapia',
        jobTitle: 'Operador'
    },
    {
        uid: 'dwIkGUU8DHMP5PYW3MPfW5ns6Qk2',
        email: 'ign.sro@gmail.com',
        name: 'Ignacio Sepulveda',
        jobTitle: 'Administrador'
    }
]

export function getUser(uid: string): User {
    return users.find(x => x.uid === uid)
}